<template>
  <div>
    <h1 id="wagtail-page-title" v-html="pageTitle"></h1>
    <div class="wagtail" v-html="pageBody"></div>
    <div v-html="jotForm"></div>
  </div>
</template>

<script>
export default {
  name: 'BasicBodyPage',
  props: {
    pageId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      pageBody: '',
      pageTitle: '',
      jotForm: '',
    }
  },
  async fetch() {
    try {
      const pageData = (
        await this.$axios.get(`/api/wagtail/pages/${this.pageId}/`)
      ).data
      this.pageBody = pageData.body || ''
      this.pageTitle = pageData.title || ''
      this.jotForm = pageData.jot_form || ''
    } catch (e) {
      console.error('exception wagtail', e)
      this.$sentry.captureException(e)
      throw e
    }
  },
  mounted() {
    if (this.jotForm) {
      // Hide title if no body is entered for the page
      if (!this.pageBody) this.pageTitle = ''
      const script = document.createElement('script')
      const doc = new DOMParser().parseFromString(this.jotForm, 'text/html')
      const src = doc.getElementsByTagName('script')[0].src
      script.setAttribute('src', src)
      document.head.appendChild(script)
    }
  },
}
</script>
