<template>
  <div v-if="$fetchState.error" class="nuxt-error">
    <Error404 />
  </div>
  <section v-else :class="classForPage">
    <BasicBodyPage :page-id="pageId" />
  </section>
</template>

<script>
import BasicBodyPage from '~/../common/components/BasicBodyPage.vue'

export default {
  name: 'WagtailBasicBodyPage',
  components: {
    BasicBodyPage,
    Error404: () => import('~/../common/components/Error/Error404'),
  },
  data() {
    return {
      pageId: 0,
      type: null,
      title: '',
    }
  },
  async fetch() {
    try {
      const resp = await this.$axios.get(
        `/api/wagtail/pages/?slug=${this.$route.path
          .replace('/', '')
          .replace('/', '')}`
      )
      this.pageId = resp.data.items[0].id
      this.type = resp.data.items[0].meta.type
      this.title = resp.data.items[0].title
    } catch (e) {
      console.error(e)
      throw e
    }
  },
  computed: {
    classForPage() {
      if (this.type === 'wa_wagtail.JotFormBasicPage') return ''
      return 'generic'
    },
  },
}
</script>
